import React from 'react'
import useTranslations from '../../../hooks/useTranslations'
import useScrollRevealEffect from '../../../hooks/effects/useScrollRevealEffect'
import cx from 'classnames'

const Quote = ({
  name,
  quote,
  position,
  logo,
  brand,
  author,
  brandWidth,
  brandHeight,
  className,
}) => {
  const t = useTranslations()

  const titleRef = useScrollRevealEffect()
  const blockRef = useScrollRevealEffect()

  return (
    <div className="quote-brick">
      <div className={cx('quote', { [className]: className })} ref={blockRef}>
        <div className="quote-bubble">
          <h2 className="quote-title" ref={titleRef}>
            {t(quote, {
              span: t => <span className="emphasis-text">{t}</span>,
            })}
          </h2>
        </div>
        <div className="quote-author">
          <div className="quote-avatar">
            <img src={author} alt={name} loading="lazy" />
          </div>
          <div className="quote-authorInfo">
            <div>
              <p className="quote-authorName">{name}</p>
              <p className="quote-authorPosition">{t(position)}</p>
            </div>
            <div className="quote-brand">
              <img
                src={logo}
                alt={brand}
                width={brandWidth}
                height={brandHeight}
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quote
