import React from 'react'
import Quote from './Quote'
import eos from '../../../assets/images/brands/eos-gray.png'
import author from '../../../assets/images/volker-kunze.png'

const QuoteEos = () => {
  return (
    <Quote
      quote="quote-eos:title"
      name="Volker Kunze"
      position="quote-eos:position"
      brand="eos"
      logo={eos}
      author={author}
      brandWidth="128"
      brandHeight="49"
    />
  )
}

export default QuoteEos
